// import loadable from '@loadable/component';
// // leave as it is: cyclic dependency
// import { Button, Input } from 'sly/components/atoms';
// import MultipleChoice from 'sly/components/molecules/MultipleChoice';
// import CommunityChoice from 'sly/components/molecules/CommunityChoice';
// import RatingInput from 'sly/components/molecules/RatingInput';
// import Slider from 'sly/components/molecules/Slider';
// import DateChoice from 'sly/components/molecules/DateChoice';
// import DateRange from 'sly/components/molecules/DateRange';
// import BoxChoice from 'sly/components/molecules/BoxChoice';
// import PhoneInput from 'sly/components/molecules/PhoneInput';
// import IconInput from 'sly/components/molecules/IconInput';
// import Toggle from 'sly/components/molecules/Toggle';
// import UserAutoComplete from 'sly/components/molecules/UserAutoComplete';
// import OrganizationAutoComplete from 'sly/components/molecules/OrganizationAutoComplete';
// import NumberInput from 'sly/components/molecules/NumberInput';
// import Autocomplete from 'sly/components/molecules/Autocomplete';
// import CheckboxInput from 'sly/components/molecules/CheckboxInput';
// import AgentAutoComplete from 'sly/components/molecules/AgentAutoComplete';
// import LocationSearch from 'sly/components/molecules/LocationSearch';
// import RichTextArea from 'sly/components/atoms/RichTextArea';
// import ToggleOptions from 'sly/components/molecules/ToggleOptions';
// import ToggleButton from 'sly/components/molecules/ToggleButton';
// import NpsScale from 'sly/components/molecules/NpsScale';
// import EmailInput from 'sly/components/molecules/EmailInput';

// const DatePicker = loadable(() => import(/* webpackChunkName: "chunkDatePicker" */'sly/components/molecules/DatePicker'));
// const Select = dynamic(() =>
//   import(/* webpackChunkName: "chunkAtomSelect" */ 'sly/system/Select')
// );

import dynamic from 'next/dynamic';

const DatePicker = dynamic(() =>
  import(
    /* webpackChunkName: "chunkDatePicker" */ '@react/form/components/Field/DatePicker'
  )
);

const Select = dynamic(() =>
  import(/* webpackChunkName: "chunkAtomSelect" */ '@react/system/Select')
);

const MultiDatePicker = dynamic(() =>
  import(
    /* webpackChunkName: "chunkMultiDatePicker" */ '@react/form/components/Field/MultiDatePicker'
  )
);
import RatingInput from '@react/form/components/Field/RatingInput';
import ToggleButton from '@react/form/components/Field/ToggleButton';

const BoxChoice = dynamic(() =>
  import(
    /* webpackChunkName: "chunkBoxChoice" */ '@react/form/components/Field/BoxChoice'
  )
);

const Input = dynamic(() =>
  import(/* webpackChunkName: "chunkInput" */ '@react/form/components/Input')
);
const LocationSearch = dynamic(() =>
  import(
    /* webpackChunkName: "chunkLocationSearch" */ '@react/form/components/LocationSearch'
  )
);

const CheckboxInput = dynamic(() =>
  import(
    /* webpackChunkName: "chunkCheckboxInput" */ '@react/form/components/CheckboxInput'
  )
);

const CommunityAutoComplete = dynamic(() =>
  import(
    /* webpackChunkName: "chunkCheckboxInput" */ '@react/form/components/CommunityAutoComplete'
  )
);

const SegmentedInput = dynamic(() =>
  import(
    /* webpackChunkName: "chunkSegmentedInput" */ '@react/form/components/SegmentedInput'
  )
);

const CommunityToggle = dynamic(() =>
  import(
    /* webpackChunkName: "chunkCommunityToggle" */ '@react/form/components/Field/CommunityToggle'
  )
);

const MultiChoice = dynamic(() =>
  import(
    /* webpackChunkName: "chunkMultiChoice" */ '@react/form/components/Field/MultiChoice'
  )
);

const SingleChoice = dynamic(() =>
  import(
    /* webpackChunkName: "chunkSingleChoice" */ '@react/form/components/Field/SingleChoice'
  )
);

const getInputComponent = (type) => {
  switch (type) {
    case 'rating':
      return RatingInput;
    // case 'singlechoice':
    // case 'multiplechoice':
    // case 'buttonlist':
    //   return MultipleChoice;
    // case 'communitychoice':
    //   return CommunityChoice;
    // case 'slider':
    //   return Slider;
    case 'boxChoice':
      return BoxChoice;
    // case 'dateChoice':
    //   return DateChoice;
    // case 'iconInput':
    //   return IconInput;
    // case 'daterange':
    //   return DateRange;
    case 'date':
      return DatePicker;
    case 'multiDate':
      return MultiDatePicker;
    case 'select':
      return Input;
    // case 'phone':
    //   return PhoneInput;
    case 'choice':
      return Select;
    // case 'autocomplete':
    //   return Autocomplete;
    case 'community':
      return CommunityAutoComplete;
    // case 'agent':
    //   return AgentAutoComplete;
    // case 'user':
    //   return UserAutoComplete;
    // case 'organization':
    //   return OrganizationAutoComplete;
    case 'checkbox':
    case 'boolean':
      return CheckboxInput;
    case 'locationSearch':
      return LocationSearch;
    // case 'richtextarea':
    //   return RichTextArea;
    // case 'button':
    //   return Button;
    // case 'number':
    //   return NumberInput;
    // case 'toggle':
    //   return Toggle;
    // case 'toggleOptions':
    //   return ToggleOptions;
    case 'toggleButton':
      return ToggleButton;
    // case 'nps':
    //   return NpsScale;
    // case 'emailInput':
    //   return EmailInput;
    case 'segmented':
      return SegmentedInput;
    case 'communityToggle':
      return CommunityToggle;
    case 'multiChoice':
      return MultiChoice;
    case 'singleChoice':
      return SingleChoice;
    default:
      return Input;
  }
};

export default getInputComponent;
