import { DisclosureFieldNames } from '@react/gwizzy/constants';
import { Community } from '@sly/core/types';
import { GwizzyCommunity } from './transformGwizzyCommunity';

export const isDisclosureEnabledForState = (state: string) => {
  return DisclosureFieldNames[state]?.isEnabled;
};

export const isD2cCommunity = (community: Community) => {
  return !!community?.rgsAux?.rgsInfo?.contract_info?.isAutoReferable;
};

export const getDisclosureEnabledStatesInD2CCommunities = (
  communities: Community[]
) => {
  return getDisclosureEnabledStates(
    communities?.map(community => {
      return {
        state: community?.address?.state,
        isD2c: isD2cCommunity(community)
      };
    })
  );
};

export const getDisclosureEnabledStatesInD2CGwizzyCommunities = (
  communities: GwizzyCommunity[]
) => {
  return getDisclosureEnabledStates(
    communities?.map(community => {
      return {
        state: community.state,
        isD2c: community.isAutoReferable
      };
    })
  );
};

const getDisclosureEnabledStates = (
  data: { state: string; isD2c: boolean }[]
) => {
  const dislosureEnabledStates = Object.entries(DisclosureFieldNames)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value?.isEnabled)
    .map(([key]) => key);

  const enabledStates = new Set();

  data?.forEach(({ state, isD2c }) => {
    if (dislosureEnabledStates.includes(state) && isD2c) {
      enabledStates.add(state);
    }
  });
  return Array.from(enabledStates);
};
