export const AVAILABLE_TAGS = {
  'United States': [
    'Assisted Living',
    'Memory Care',
    'Independent Living',
    'Board and Care Home',
    'Skilled Nursing Facility',
    'Continuing Care Retirement Community(CCRC)',
    'Active Adult Communities (55+)'
  ],
  Canada: [
    'Assisted Living',
    'Memory Care',
    'Independent Living',
    'Board and Care Home',
    'Skilled Nursing Facility',
    'Continuing Care Retirement Community(CCRC)'
  ],
  'United Kingdom': ['Care Home']
};
export const ASSISTED_LIVING = 'Assisted Living';
export const CCRC = 'Continuing Care Retirement Community';
export const CONTINUING_CARE_RETIREMENT_COMMUNITY =
  'Continuing Care Retirement Community(CCRC)';
export const ACTIVE_ADULT = 'Active Adult Communities (55+)';
export const SKILLED_NURSING_FACILITY = 'Skilled Nursing Facility';
export const PLUS_TAG = 'Plus';
export const VERIFIED_TAG = 'VERIFIED';
export const INDEPENDENT_LIVING = 'Independent Living';

export const CARE_TYPE_DESCRIPTION_MAP = {
  'Assisted Living':
    "For seniors who need some help with daily activities and want a supportive community that's active, social, and engaging.",
  'Memory Care':
    'A supervised and secured community designed to support engagement and quality of life for residents living with dementia.',
  'Independent Living':
    "For active older adults who want to downsize to a home in a retirement community but don't need help to live independently.",
  'Board and Care Home':
    'Homes in residential neighborhoods that are equipped and staffed to provide daily care for a small number of residents.',
  'Continuing Care Retirement Community':
    'For residents who want to age in place as their care needs change—from Independent Living to nursing care. Requires a buy-in fee.',
  'Active Adult Communities (55+)':
    'Communities of houses and apartments for residents 55 and older who live independently, enjoying an active, social lifestyle.',
  'Skilled Nursing Facility':
    'For seniors with more serious medical needs who require skilled care following a hospitalization, illness, or surgery. Nursing Homes are also known as skilled nursing facilities.'
};

export const NURSING_HOME = 'Nursing Home';
