/** @jsxImportSource react */
import { getTimeZoneForGwizzy } from '@common/helpers/date';
import postConversionAsyncHandler from '@react/gwizzy/asyncHandlers/postConversionAsyncHandler';
import GwizzyWizard from '@react/gwizzy/components/GwizzyWizard';
import { getNewPostConversionSchema } from '@react/gwizzy/schema/postConversionSchema';
import { useQuery } from '@react/services/api';
import { useAuth } from '@react/services/auth';
import { useEvents } from '@react/services/events';
import { useMemo } from 'react';

export default function PostConversionWizard({
  onDone,
  clientMeta,
  community,
  title = '',
  formWrapperClassName = '',
  customSchema = null,
  hasExperimentalOnBack = false,
  isChatMode = false,
}) {
  const { events } = useEvents();
  const createAction = useQuery('createUuidAction');
  const auth = useAuth();
  const timeZone = getTimeZoneForGwizzy(community?.timeZone, auth?.user);

  const postConversionSchema = useMemo(
    () => getNewPostConversionSchema({ clientMeta, timeZone }),
    [clientMeta, timeZone]
  );

  let initialStep = 0;

  if (clientMeta?.skipLookingFor) {
    initialStep = 1;
  }

  if (clientMeta?.skipResidentName) {
    initialStep = 2;
  }

  return (
    <GwizzyWizard
      initialValues={{
        moreInfoFromLeadChoice: 'Yes',
        lookingFor: clientMeta?.lookingFor,
      }}
      asyncActionHandlers={postConversionAsyncHandler({
        events,
        createAction,
        auth,
        community,
        onDone,
        timeZone,
        clientName: auth?.user?.name,
      })}
      formSchema={customSchema || postConversionSchema}
      localStorageKey='postConversion'
      title={title}
      formWrapperClassName={formWrapperClassName}
      hasExperimentalOnBack={hasExperimentalOnBack}
      successMessage='Thank you, we are working on your request.'
      isChatMode={isChatMode}
      initialStep={initialStep}
    />
  );
}
