/** @jsxImportSource react */
import cx from '@common/lib/clsxm';
import { eventNames, useEvents } from '@react/services/events';
import Chevron from '@sly/icons/react/Chevron';
import React, { ReactElement, useRef, useState } from 'react';

interface AccordianProps {
  title: React.ReactNode;
  children: ReactElement<any, any>;
  variant: string;
  openByDefault: boolean;
  eventProps: Record<string, string>;
  renderOnlyIfOpen?: boolean;
  className?: string;
  wrapperClassName?: string;
}

const Accordian: React.FC<AccordianProps> = ({
  title,
  children,
  variant,
  openByDefault,
  eventProps,
  renderOnlyIfOpen = false,
  className,
  wrapperClassName,
}) => {
  const [active, setActive] = useState(openByDefault);
  const { events } = useEvents();

  const getTableClass = () => {
    if (variant === 'A') {
      if (active) {
        return 'border';
      } else {
        return 'border border-slate-lighter-90';
      }
    }
    return '';
  };

  const getHeaderClass = () => {
    if (variant === 'A') {
      return 'bg-slate-lighter-90 md:py-m md:px-l p-m';
    }
    return '';
  };

  const contentSpace = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActive((prevState) => !prevState);

    events.track(eventNames.ButtonClicked, {
      cta: active ? 'Accordion closed' : 'Accordion opened',
      text: title,
      ...eventProps,
    });
  }

  return (
    <div
      className={cx('flex flex-col rounded', getTableClass(), wrapperClassName)}
      data-tp-id='gen-div-49f21334-2ab1-4e06-8b78-159d473bdeaf'
    >
      <div
        className={cx(
          'box-border grid cursor-pointer',
          'appearance-none grid-cols-[auto,24px] items-start justify-between',
          'gap-xs',
          'focus:outline-none',
          getHeaderClass()
        )}
        onClick={toggleAccordion}
        data-tp-id='gen-div-24691542-5fe1-4c60-8ec0-559e921e7b71'
      >
        <h3
          className='font-t-xs-azo inline-block text-left'
          data-tp-id='gen-h3-9bc92f87-bf10-472c-b5a1-35513a60002f'
        >
          {title}
        </h3>
        <Chevron size='m' className={active ? '' : 'rotate-180'} />
      </div>
      <div
        ref={contentSpace}
        style={{
          maxHeight: active ? '200rem' : '0',
        }}
        className={cx(
          'no-scrollbar overflow-auto',
          'overflow-y-hidden',
          active ? 'duration-500 ease-in-out' : 'duration-200 ease-in-out'
        )}
        data-tp-id='gen-div-1f08237a-2a50-45ad-9653-0304e7c73e2b'
      >
        <div
          className={cx(
            'font-b-m pb-4 [&_a]:text-viridian-base [&_a:hover]:underline',
            className
          )}
          data-tp-id='gen-div-194de07d-f2ac-487a-a2eb-d31527d30efd'
        >
          {renderOnlyIfOpen && !active ? null : children}
        </div>
      </div>
    </div>
  );
};

export default Accordian;
