/** @jsxImportSource react */
import {
  getFirst4StarReview,
  getFormattedAddress,
} from '@common/helpers/community';
import { transformGwizzyCommunityFromSearchResourceModel } from '@common/helpers/transformGwizzyCommunity';
import clsxm from '@common/lib/clsxm';
import useCommunityCard from '@react/card/hooks/useCommunityCard';
import GwizzyCta from '@react/cta/GwizzyCta';
import { entity as entityPropType } from '@react/propTypes/entity';
import CommunityRating from '@react/shared/components/CommunityRating';
import RatingBadge from '@react/shared/components/RatingBadge';
import {
  convertZeroToHundredToScaleOfTen,
  getScoreTextAndIntent,
} from '@react/shared/helpers/ratingBadge';
import { Button } from '@react/system';
import { Heading, Link } from '@react/system';
import { DEFAULT_CTA_OBJECTS } from '@sly/core/constants/strapi';
import {
  NURSING_HOME,
  SKILLED_NURSING_FACILITY,
} from '@sly/core/constants/tags';
import {
  leadSourceMap,
  marketingChannelMap,
  marketingMediumMap,
} from '@sly/core/constants/uuidActions';
import { formatMoney } from '@sly/core/helpers/numbers';
import { Close, Favorite } from '@sly/icons/react';
import cx from 'classnames';
import { bool, object, string } from 'proptypes';
import striptags from 'striptags';

import {
  button,
  hiddenMap,
  hiddenRecs,
  ratingAndPricing,
  showRecs,
} from './CardClasses';
import EntityInfoDescription from './CardInfoDescription';
import EntityText from './CardText';

const EntityInfo = (props) => {
  const {
    entity,
    inverted,
    color,
    headerIsLink,
    event,
    intent,
    hideDescription,
    like,
    dislike,
    liked,
    disliked,
  } = props;

  let {
    addressString,
    id,
    description,
    startingRate,
    maxRate,
    estimatedPricingOverride,
    reviewsValue,
    numReviews,
    typeCare,
    isPricingHidden,
    secondLine,
    slyScore,
  } = useCommunityCard({ entity });

  const { priceTextSize } = props;
  const { propInfo = {} } = entity;
  let { rating } = entity;
  // const capacity = propInfo.capacity || entity.capacity;
  let livingTypeComponent = null;
  let descriptionTypeComponent = null;

  if (!rating) {
    rating = getFirst4StarReview(entity);
  }

  if (!description) {
    const { communityDescription } = propInfo;
    description = communityDescription?.substring(0, 400);
  }

  if (!addressString) {
    addressString = getFormattedAddress(entity);
  }

  if ((typeCare && typeCare.length) || secondLine) {
    if (typeCare?.length) {
      typeCare = typeCare.map((care) => {
        if (care === SKILLED_NURSING_FACILITY) {
          return NURSING_HOME;
        }
        return care;
      });
    }
    livingTypeComponent = (
      <EntityInfoDescription>
        {typeCare?.map(
          (care, i) => `${care}${i < typeCare.length - 1 ? ', ' : ''}`
        )}
      </EntityInfoDescription>
    );
  }

  const descriptionContent = rating
    ? `"${rating?.comments}" -  ${rating?.ratingByName}`
    : description && description !== ''
    ? description
    : 'No description available';

  descriptionTypeComponent = (
    <div
      className={cx(
        'flex flex-row gap-2',
        hiddenMap({ intent, display: 'flex' }),
        hiddenRecs({ intent })
      )}
    >
      <EntityText
        className='mt-2'
        inverted={inverted}
        slug={id}
        intent={intent}
      >
        {striptags(descriptionContent)}
      </EntityText>
    </div>
  );

  const headerContent = (
    <Heading
      className={cx(
        'font-t-s md:font-t-s',
        'mb-1',
        inverted ? 'text-white' : 'text-slate-base',
        'overflow-hidden text-ellipsis whitespace-nowrap'
      )}
      as='h3'
      font='title-small'
      title={entity.name}
      data-tp-id='gen-Heading-2dad9002-e01e-4075-a759-702d0fc18603'
    >
      {entity.name}
    </Heading>
  );

  const header = headerIsLink ? (
    <Link
      href={entity.url}
      event={event}
      data-tp-id='gen-Link-83abf50f-c7fe-44c8-9091-cb1c078042fc'
    >
      {headerContent}
    </Link>
  ) : (
    headerContent
  );

  const { color: scoreColor } = getScoreTextAndIntent(
    convertZeroToHundredToScaleOfTen(slyScore)
  );

  return (
    <div
      className='flex h-full flex-col items-stretch justify-between'
      data-tp-id='gen-div-8e752f82-4c79-4083-9b70-45f1cf3dee1d'
    >
      <div data-tp-id='gen-div-afc925ae-3286-4f1a-b3f5-1ac3667bb64c'>
        {header}
        {addressString && (
          <EntityInfoDescription
            className={hiddenMap({ intent })}
            inverted={inverted}
          >
            {addressString}
          </EntityInfoDescription>
        )}
        {livingTypeComponent}
        {descriptionTypeComponent}
      </div>
      <div
        className={ratingAndPricing({ intent })}
        data-tp-id='gen-div-efa2467c-2e75-478c-bb73-1cbb9e533d9a'
      >
        {!isPricingHidden && startingRate ? (
          <div
            className={cx(
              color || (inverted ? 'text-white' : 'text-black'),
              'font-b-m',
              'overflow-hidden text-ellipsis whitespace-nowrap'
            )}
            data-tp-id='gen-div-c9a72aef-38e4-46f8-b685-65d9c1de7013'
          >
            <div
              className={cx('inline', priceTextSize, 'font-medium')}
              data-tp-id='gen-div-8120bfa5-40f9-491c-9708-ac4c3dcc9350'
            >
              From {formatMoney(startingRate)}
            </div>
            /mo
          </div>
        ) : null}
        <div
          className={cx(
            inverted ? 'text-white' : 'text-viridian-base',
            'flex flex-row'
          )}
          data-tp-id='gen-div-9b2da122-880a-427b-826a-1eb0caaa56c3'
        >
          {!Number.isNaN(convertZeroToHundredToScaleOfTen(slyScore)) && (
            <RatingBadge
              intent={scoreColor}
              size='small'
              className={clsxm(!numReviews && '!mr-0')}
              rating={convertZeroToHundredToScaleOfTen(slyScore)}
            />
          )}
          <CommunityRating
            seedId={entity.id}
            rating={reviewsValue}
            numReviews={numReviews}
            reviewsText
            textClassName='font-b-s slate-lighter-40'
            wrapperClassName='gap-0'
            numberOnly
            intent={intent}
          />
        </div>
      </div>
      <div
        className={cx(
          'mt-4 flex flex-row justify-end gap-2',
          hiddenMap({ intent, display: 'flex' }),
          hiddenRecs({ intent })
        )}
      >
        <Button
          eventProps={{ location: 'Community Tile', communitySlug: id }}
          className={button({ intent, className: 'rounded-lg !py-xs' })}
          variant='neutral'
        >
          See details
        </Button>
        <GwizzyCta
          className={button({ intent, className: 'rounded-lg !py-xs' })}
          community={entity}
          flatCommunity={{ community: entity, ...entity }}
          gwizzyCommunity={transformGwizzyCommunityFromSearchResourceModel(
            entity
          )}
          mode={{
            leadSource: leadSourceMap.GetPricingCommunityTile,
            channel: marketingChannelMap.OrganicMC,
            medium: marketingMediumMap.OnlineMM,
          }}
          eventProps={{
            location: 'Community Tile',
            text: 'Get pricing',
            communitySlug: id,
          }}
          ctaProps={{ ...DEFAULT_CTA_OBJECTS.gwizzy, text: 'Get pricing' }}
        />
      </div>
      <div
        className={cx(
          'mt-4 flex flex-row justify-end gap-2',
          showRecs({ intent })
        )}
      >
        <Button
          eventProps={{
            location: 'recommendationModule',
            communitySlug: id,
            text: 'Not a good fit',
          }}
          className={button({ intent, className: 'font-t-xxs-azo !px-4' })}
          variant='neutral'
          onClick={(e) => {
            e.preventDefault();
            dislike();
          }}
          disabled={disliked}
        >
          <Close size='s' className='mr-2' />
          Not a good fit
        </Button>
        <Button
          eventProps={{
            location: 'recommendationModule',
            communitySlug: id,
            text: 'Favorite',
          }}
          className={button({ intent, className: 'font-t-xxs-azo !px-4' })}
          variant='neutral'
          onClick={(e) => {
            e.preventDefault();
            like();
          }}
          disabled={liked}
        >
          <Favorite
            size='s'
            active={liked}
            className={clsxm('mr-2', liked && 'text-red-base')}
          />
          Save
        </Button>
      </div>
    </div>
  );
};

EntityInfo.propTypes = {
  entity: entityPropType,
  inverted: bool,
  color: string,
  className: string,
  headerIsLink: bool,
  event: object,
  priceTextSize: string.isRequired,
  swapRatingPrice: bool,
};

EntityInfo.defaultProps = {
  priceTextSize: 'font-t-xs-azo',
  type: 'list',
};

export default EntityInfo;
