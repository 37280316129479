import { AmenitiesAndServiceType } from './../api/types/amenities';

export const healthCareService = 'healthCareService';
export const roomAmenitiesType = 'roomAmenities';
export const communityAmenitiesType = 'communityAmenities';
export const communityServiceType = 'communityService';
export const diningServiceType = 'diningService';

export const amenitiesAndServiceTitles = {
  healthCareService: 'Health care services',
  roomAmenities: 'Room amenities',
  communityAmenities: 'Community amenities',
  communityService: 'Community services',
  diningService: 'Dining services'
};

export const amenitiesAndServiceTypes: Record<AmenitiesAndServiceType, any> = {
  healthCareService: healthCareService,
  roomAmenities: roomAmenitiesType,
  communityAmenities: communityAmenitiesType,
  communityService: communityServiceType,
  diningService: diningServiceType
};

export const amenitiesAndServiceItems: Record<string, any> = {
  [amenitiesAndServiceTypes.healthCareService]: {
    specializedMemoryCareProgram: {
      label: 'Specialized memory care programming',
      icon: 'Alzheimer',
      excludeForFilter: true
    },
    hasMildCognitiveImpairment: {
      label: 'Mild cognitive impairment',
      icon: 'Alzheimer'
    },
    mentalWellnessProgram: {
      label: 'Mental wellness program',
      icon: 'Alzheimer',
      excludeForFilter: true
    },
    hasDementiaWaiver: {
      label: 'Dementia waiver',
      icon: 'Alzheimer'
    },
    hasMedicationManagement: {
      label: 'Medication management',
      icon: 'Pill'
    },
    has24HourSupervision: {
      label: '24-hour supervision',
      icon: 'Time'
    },
    hasActivitiesOfDailyAssistance: {
      label: 'Activities of daily living assistance',
      icon: 'Care'
    },
    hasAssistanceBathing: {
      label: 'Assistance with bathing',
      icon: 'Care'
    },
    hasAssistanceDressing: {
      label: 'Assistance with dressing',
      icon: 'Care'
    },
    hasAssistanceTransfer: {
      label: 'Assistance with transfers',
      icon: 'Care'
    },
    caregiversLanguageSpoken: {
      label: 'Caregivers languages spoken',
      icon: 'Language',
      excludeForFilter: true
    },
    hasDiabetesCare: {
      label: 'Diabetes care',
      icon: 'Care'
    },
    hasAdministerInsulin: {
      label: 'Administer insulin injections',
      icon: 'Care'
    },
    hasIncontinenceCare: {
      label: 'Incontinence care',
      icon: 'Restroom'
    },
    hasNonAmbulatoryCare: {
      label: 'Non-ambulatory care',
      icon: 'Mobility'
    },
    hasParkinsonCare: {
      label: "Parkinson's care",
      icon: 'Care'
    },
    hasCareWithBehavioral: {
      label: 'Care with behavioral issues',
      icon: 'Care'
    },
    hasHospiceWaiver: {
      label: 'Hospice waiver',
      icon: 'Care'
    },
    hasRespiteProgram: {
      label: 'Respite program',
      icon: 'Care',
      excludeForFilter: true
    },
    has24HourCallSystem: {
      label: '24-hour call system',
      icon: 'Care'
    },
    has12To16HourNursing: {
      label: '12-16 hour nursing',
      icon: 'Care'
    },
    has24HourNursing: {
      label: '24-hour nursing',
      icon: 'Care'
    },
    hasHearingAssistiveDevices: {
      label: 'Hearing assistive devices',
      icon: 'Hearing'
    },
    hasCoordinationWithHealthCareProviders: {
      label: 'Coordination with health care providers',
      icon: 'Care'
    },
    hasPreventiveHealthScreenings: {
      label: 'Preventive health screenings',
      icon: 'Stethoscope'
    },
    hasPhysicalTherapy: {
      label: 'Physical therapy',
      icon: 'Care'
    },
    rehabilitationProgram: {
      label: 'Rehabilitation program',
      icon: 'Care',
      excludeForFilter: true
    },
    caregiverCertifications: {
      label: 'Caregiver certifications',
      icon: 'Safety',
      excludeForFilter: true
    },
    caregiverNurseQualification: {
      label: 'Caregiver nurse qualifications',
      icon: 'Safety',
      excludeForFilter: true
    },
    hasWheelChairAccessible: {
      label: 'Wheelchair accessible',
      icon: 'Accessible'
    },
    hasSafetyRails: {
      label: 'Safety rails',
      icon: 'HealthSafety'
    },
    hasPersonalEmergencyResponseDevice: {
      label: 'Personal emergency response device',
      icon: 'Emergency'
    },
    hasCognitiveLearningCenter: {
      label: 'Cognitive learning center',
      icon: 'Memory'
    },
    hasSmokeAlarmAndSprinklerSystem: {
      label: 'Smoke alarm and sprinkler system',
      icon: 'SmokeDetector'
    }
  },
  [amenitiesAndServiceTypes.roomAmenities]: {
    hasPrivateBathroom: {
      label: 'Private bathroom',
      icon: 'Bathroom'
    },
    hasKitchenette: {
      label: 'Kitchenette',
      icon: 'Kitchen'
    },
    hasAirConditioning: {
      label: 'Air conditioning',
      icon: 'Ac'
    },
    hasWifi: {
      label: 'Wi-Fi / high-speed internet',
      icon: 'Wifi'
    },
    hasCable: {
      label: 'Cable TV',
      icon: 'Tv'
    },
    hasTelephone: {
      label: 'Telephone',
      icon: 'Phone'
    },
    hasFurniture: {
      label: 'Furnished',
      icon: 'Couch'
    },
    hasWasherAndDryer: {
      label: 'Washer and dryer',
      icon: 'Washer'
    }
  },
  [amenitiesAndServiceTypes.communityAmenities]: {
    hasDayTrips: {
      label: 'Day trips',
      icon: 'Checkmark'
    },
    hasOutdoorPrograms: {
      label: 'Outdoor programs',
      icon: 'Checkmark'
    },
    hasFitnessPrograms: {
      label: 'Fitness programs',
      icon: 'Fitness'
    },
    hasMovieNights: {
      label: 'Movie nights',
      icon: 'Video'
    },
    hasResidentRunActivities: {
      label: 'Resident-run activities',
      icon: 'Checkmark'
    },
    hasScheduledDailyActivities: {
      label: 'Scheduled daily activities',
      icon: 'Time'
    },
    hasCommunitySponsoredActivities: {
      label: 'Community-sponsored activities',
      icon: 'Checkmark'
    },
    hasGameRoom: {
      label: 'Game room',
      icon: 'Games'
    },
    hasMovieRoom: {
      label: 'Movie theater',
      icon: 'Entertainment'
    },
    hasActivityRoom: {
      label: 'Activity room',
      icon: 'Checkmark'
    },
    hasArtsRoom: {
      label: 'Arts room',
      icon: 'Arts'
    },
    hasLibrary: {
      label: 'Library',
      icon: 'Book'
    },
    hasBusinessRoom: {
      label: 'Business room',
      icon: 'Business'
    },
    hasChapel: {
      label: 'Chapel',
      icon: 'Church'
    },
    hasFitnessRoom: {
      label: 'Fitness room',
      icon: 'Fitness'
    },
    hasSpaWellnessRoom: {
      label: 'Spa / sauna / wellness room',
      icon: 'Spa'
    },
    hasBarberSalon: {
      label: 'Barber / Salon',
      icon: 'Spa'
    },
    hasSwimmingPool: {
      label: 'Swimming pool / jacuzzi',
      icon: 'Pool'
    },
    hasTherapyRoom: {
      label: 'Therapy room',
      icon: 'Checkmark'
    },
    hasWalkingPaths: {
      label: 'Walking paths',
      icon: 'Walk'
    },
    hasGarden: {
      label: 'Garden',
      icon: 'Flower'
    },
    hasCourtyard: {
      label: 'Courtyard',
      icon: 'Deck'
    },
    petFriendly: {
      label: 'Pet-friendly',
      icon: 'Pet',
      excludeForFilter: true
    },
    hasEmergencyAlertSystem: {
      label: 'Emergency Alert System',
      icon: 'Emergency'
    },
    hasOnsiteMarket: {
      label: 'On-site market / Store',
      icon: 'Food'
    },
    hasFireplace: {
      label: 'Fireplace',
      icon: 'Fireplace'
    },
    hasElevators: {
      label: 'Elevators',
      icon: 'Elevator'
    },
    hasPiano: {
      label: 'Piano',
      icon: 'Music'
    },
    religiousAffinities: {
      label: 'Religious affinities',
      icon: 'Religion',
      excludeForFilter: true
    },
    hasMusicProgram: {
      label: 'Music programs',
      icon: 'Music'
    },
    hasVideoTours: {
      label: 'Video tours offered',
      icon: 'Video'
    },
    hasOvernightVisitors: {
      label: 'Overnight visitors',
      icon: 'Bed'
    },
    hasParkingAvailable: {
      label: 'Parking available',
      icon: 'Parking'
    },
    hasCommunityOperatedTransportation: {
      label: 'Community operated transportation',
      icon: 'Checkmark'
    },
    hasTransportationArrangement: {
      label: 'Transportation arrangement',
      icon: 'Transportation'
    },
    hasGuestSuites: {
      label: 'Guest suites',
      icon: 'Bed'
    },
    hasLgbtqs: {
      label: 'LGBTQ inclusive',
      icon: 'Checkmark'
    },
    hasOnSitePhysicianOfficeSpace: {
      label: 'On-site physician office space',
      icon: 'Stethoscope'
    },
    hasLockedMailboxes: {
      label: 'Locked mailboxes',
      icon: 'Mail'
    },
    hasAutomaticOnlinePayments: {
      label: 'Automatic online payments',
      icon: 'Payment'
    },
    hasPatio: {
      label: 'Patio',
      icon: 'Deck'
    },
    hasOutdoorCommonSpace: {
      label: 'Outdoor common space',
      icon: 'Outdoors'
    },
    hasAmazonEchoShow: {
      label: 'Amazon Echo show',
      icon: 'Awesome'
    },
    hasTrashChutes: {
      label: 'Trash chutes',
      icon: 'Delete'
    },
    hasPuttingGreen: {
      label: 'Putting green',
      icon: 'Golf'
    },
    hasKoiPond: {
      label: 'Koi pond',
      icon: 'Water'
    },
    hasNewspaperDelivery: {
      label: 'Newspaper delivery',
      icon: 'Article'
    },
    hasCottages: {
      label: 'Cottages',
      icon: 'HomeSmallStroke'
    },
    hasStorageUnitsAvailable: {
      label: 'Storage units available',
      icon: 'Storage'
    },
    hasPoolsideClubhouse: {
      label: 'Poolside clubhouse',
      icon: 'HomeSmallStroke'
    },
    hasVolunteerPrograms: {
      label: 'Volunteer program',
      icon: 'BestCare'
    }
  },
  [amenitiesAndServiceTypes.communityService]: {
    hasHouseKeeping: {
      label: 'Housekeeping',
      icon: 'Laundry'
    },
    hasLaundry: {
      label: 'Laundry service / dry cleaning',
      icon: 'Laundry'
    },
    hasFamilySupportServices: {
      label: 'Family support services',
      icon: 'Checkmark'
    },
    hasMoveInCoOrdination: {
      label: 'Move-in coordination',
      icon: 'Book'
    },
    hasConcierge: {
      label: 'Concierge',
      icon: 'RoomService'
    },
    hasContinuingLearningExperience: {
      label: 'Continuing learning experience',
      icon: 'Book'
    },
    hasPostalServices: {
      label: 'Postal services',
      icon: 'Mail'
    },
    hasCovidPolicy: {
      label: 'Covid policy',
      icon: 'Covid'
    },
    hasSameDayAssessments: {
      label: 'Same-day assessments',
      icon: 'Checkmark'
    },
    hasPreventiveScreening: {
      label: 'Preventive screenings',
      icon: 'Checkmark'
    },
    hasRentersLiabilityInsuranceProgram: {
      label: 'Renters liability insurance program',
      icon: 'Contract'
    },
    hasMostUtilitiesIncluded: {
      label: 'Most utilities included',
      icon: 'Lightbulb'
    },
    hasMaintenance: {
      label: 'Maintenance',
      icon: 'Construction'
    }
  },
  [amenitiesAndServiceTypes.diningService]: {
    hasDiningRoom: {
      label: 'Dining room',
      icon: 'TableBar'
    },
    hasRestaurantStyleDining: {
      label: 'Restaurant-style dining',
      icon: 'Food'
    },
    hasCafe: {
      label: 'Cafe',
      icon: 'Cafe'
    },
    diningOptions: {
      label: 'Dining options',
      icon: 'Checkmark',
      excludeForFilter: true
    },
    mealPreparationAndService: {
      label: 'Meal preparation and service',
      icon: 'Checkmark',
      excludeForFilter: true
    },
    hasAllergySensitive: {
      label: 'Allergy-sensitive',
      icon: 'Checkmark'
    },
    hasDiabetesDiet: {
      label: 'Diabetes diet',
      icon: 'Checkmark'
    },
    hasVegetarian: {
      label: 'Vegetarian',
      icon: 'Checkmark'
    },
    hasInternationalCuisine: {
      label: 'International cuisine',
      icon: 'Checkmark'
    },
    hasSpecialDietaryRestrictions: {
      label: 'Special dietary restrictions',
      icon: 'Checkmark'
    },
    menuHighlights: {
      label: 'Menu highlights',
      icon: 'Food',
      excludeForFilter: true
    },
    aboutTheChef: {
      label: 'About the chef',
      icon: 'Food',
      excludeForFilter: true
    },
    hasSelectionOfAlteredTextureOptions: {
      label: 'Selection of altered-texture options',
      icon: 'Food'
    },
    hasOutdoorDining: {
      label: 'Outdoor dining',
      icon: 'Deck'
    },
    hasGrill: {
      label: 'Grill',
      icon: 'Grill'
    },
    hasSportsOrCocktailLounge: {
      label: 'Sports / cocktail lounge',
      icon: 'Drink'
    },
    hasBistro: {
      label: 'Bistro',
      icon: 'Deck'
    },
    hasLocallyInspiredCuisine: {
      label: 'Locally-inspired cuisine',
      icon: 'Food'
    },
    hasIceCreamParlor: {
      label: 'Ice cream parlor',
      icon: 'IceCream'
    },
    hasPatioDining: {
      label: 'Patio dining',
      icon: 'Deck'
    },
    hasKosherMeals: {
      label: 'Kosher meals',
      icon: 'Food'
    }
  }
};
