import { cmsUrl, greenhouseUrl } from 'sly/config';

import { destroy, get, patch, post, put } from './httpMethods';

// method names should start with `get`, `create`, `update`, `delete`
// patch is prefered over put as per specification
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser: { method: get, path: '/platform/users/:id', ssrIgnore: true },
  updateUser: { method: patch, path: '/platform/users/:id' },

  getUsers: { method: get, path: '/platform/users' },
  createUser: { method: post, path: '/platform/users' },

  adminGetUsers: { method: get, path: '/admin/users' },
  adminUpdateUser: { method: patch, path: '/admin/users/:id' },
  adminCreateUser: { method: post, path: '/admin/users' },

  // This route removes current org, sets role to 1, and sets user to inactive
  deleteUser: { method: destroy, path: '/platform/users/:id' },

  getUuidActions: {
    method: get,
    path: '/platform/uuid-actions',
    ssrIgnore: true,
  },
  createUuidAction: { method: post, path: '/platform/uuid-actions' },
  isGwizzyQuestionRequired: {
    method: post,
    path: '/marketplace/gwizzy/question',
  },
  getUuidAux: {
    method: get,
    path: '/platform/uuid-auxes/:id',
    ssrIgnore: true,
  },
  updateUuidAux: { method: patch, path: '/platform/uuid-auxes/:id' },
  createUuidAux: { method: post, path: '/platform/uuid-auxes' },

  createUserShare: {
    method: post,
    path: '/platform/user-shares',
    jsonApi: false,
  },

  getUserSaves: {
    method: get,
    path: '/marketplace/user-saves',
    ssrIgnore: true,
  },
  getUserSave: {
    method: get,
    path: '/marketplace/user-saves/:id',
    ssrIgnore: true,
  },
  updateUserSave: { method: patch, path: '/marketplace/user-saves/:id' },
  createUserSave: { method: post, path: '/marketplace/user-saves' },

  getHomeBase: {
    method: get,
    path: '/marketplace/homebase/:id',
    ssrIgnore: true,
  },

  registerUser: { method: post, path: '/platform/auth/register' },
  loginUser: { method: post, path: '/platform/auth/login' },
  logoutUser: { method: destroy, path: '/platform/auth/logout' },
  thirdPartyLogin: { method: post, path: '/platform/auth/third-party' },
  setPassword: { method: post, path: '/platform/auth/password' },
  updatePassword: { method: post, path: '/platform/auth/password/update' },
  recoverPassword: { method: post, path: '/platform/auth/recover' },
  resetPassword: { method: post, path: '/platform/auth/recover/end' },
  otpLoginUser: { method: post, path: '/platform/auth/otp/login' },
  resendOtpCode: { method: post, path: '/platform/auth/otp/retry' },
  sendOtpCode: { method: post, path: '/platform/auth/otp/start' },
  magicLink: { method: post, path: '/platform/auth/mlink/start' },
  authStart: { method: post, path: '/platform/auth/start' },
  invoicedMagicLink: { method: get, path: '/platform/invoiced/magic-link' },

  getCommunity: { method: get, path: '/marketplace/communities/:id' },
  claimCommunity: { method: get, path: '/marketplace/communities/:id/claim' },
  getCommunities: { method: get, path: '/marketplace/communities' },
  updateCommunity: { method: patch, path: '/marketplace/communities/:id' },
  createCommunity: { method: post, path: '/marketplace/communities' },
  updateRgsAux: { method: patch, path: '/marketplace/rgsAux/:id' },
  approveEdit: { method: post, path: '/platform/suggested-edits/:id/approve' },
  rejectEdit: { method: post, path: '/platform/suggested-edits/:id/reject' },
  getCommunityNew: { method: get, path: '/marketplace/communitiesnew/:id' },
  getCommunitiesNew: { method: get, path: '/marketplace/communitiesnew' },

  getRecommendations: {
    method: get,
    path: '/platform/recommendations/community/postconversion',
  },

  // stats
  getStats: { method: get, path: '/marketplace/stats' },

  getListing: { method: get, path: '/marketplace/listings/:id' },
  getListings: { method: get, path: '/marketplace/listings' },
  updateListing: { method: patch, path: '/marketplace/listings/:id' },
  createListing: { method: post, path: '/marketplace/listings' },

  createTag: { method: post, path: '/platform/tags' },
  getTagCategories: { method: get, path: '/platform/tag-categories' },

  getImageCategories: { method: get, path: '/platform/image-categories' },

  createImage: { method: post, path: '/platform/images' },
  updateImage: { method: patch, path: '/platform/images/:id' },
  deleteImage: { method: destroy, path: '/platform/images/:id' },

  getAgent: { method: get, path: '/marketplace/agents/:id' },
  getAgents: { method: get, path: '/marketplace/agents' },
  getAgentsNew: { method: get, path: '/marketplace/agentsnew' },
  getAgentSearch: { method: get, path: '/marketplace/agentsnew/search' },
  updateAgent: { method: put, path: '/marketplace/agents/:id' },
  createAgent: { method: post, path: '/marketplace/agents' },

  getSearchPage: { method: get, path: '/marketplace/search/searchpage' },
  getCommunityPins: { method: get, path: '/marketplace/search/communitypins' },
  getReferralSearchPage: {
    method: get,
    path: '/marketplace/search/community-referral',
  },
  getCommunitySearch: { method: get, path: '/platform/community-search' },
  getSearchResources: { method: get, path: '/platform/search-resources' },
  getSearch: { method: get, path: '/marketplace/search' },
  getGeoGuides: { method: get, path: '/platform/geo-guides' },
  getGeoGuidesAll: { method: get, path: '/platform/geo-guides/:state/:city' },
  getSearchCommunity: { method: get, path: '/marketplace/search/community' },

  createQuestion: { method: post, path: '/platform/questions' },
  createAnswer: { method: post, path: '/platform/answers', jsonApi: false },

  getClients: { method: get, path: '/marketplace/clients', ssrIgnore: true },
  getClient: { method: get, path: '/marketplace/clients/:id' },
  updateClient: { method: patch, path: '/marketplace/clients/:id' },
  createClient: { method: post, path: '/marketplace/clients' },

  // Generate Resource route link
  getResourceLink: { method: post, path: '/platform/clients-access/contact' },

  // Secure routes
  getClientsSecure: { method: get, path: '/secure/clients' },
  getClientSecure: { method: get, path: '/secure/clients/:id' },
  updateClientSecure: { method: patch, path: '/secure/clients/:id' },

  createNote: { method: post, path: '/marketplace/notes' },
  getNotes: { method: get, path: '/marketplace/notes' },
  updateNote: { method: patch, path: '/marketplace/notes/:id' },

  getConversations: { method: get, path: '/platform/conversations' },
  createConversation: { method: post, path: '/platform/conversations' },
  getConversation: { method: get, path: '/platform/conversations/:id' },
  updateConversation: { method: patch, path: '/platform/conversations/:id' },

  getConversationMessages: {
    method: get,
    path: '/platform/conversation-messages',
  },
  createConversationMessage: {
    method: post,
    path: '/platform/conversation-messages',
  },
  updateConversationMessage: {
    method: patch,
    path: '/platform/conversation-messages/:id',
  },

  getConversationParticipants: {
    method: get,
    path: '/platform/conversation-participants',
  },
  createConversationParticipant: {
    method: post,
    path: '/platform/conversation-participants',
  },
  updateConversationParticipant: {
    method: patch,
    path: '/platform/conversation-participants/:id',
  },

  getVoiceCalls: { method: get, path: '/platform/communications/voice' },
  getVoiceCall: { method: get, path: '/platform/communications/voice/:id' },

  getTask: { method: get, path: '/platform/tasks/:id' },
  getTasks: { method: get, path: '/platform/tasks' },
  createTask: { method: post, path: '/platform/tasks' },
  updateTask: { method: patch, path: '/platform/tasks/:id' },

  getContacts: { method: get, path: '/platform/contacts' },
  createContact: { method: post, path: '/platform/contacts' },
  updateContact: { method: patch, path: '/platform/contacts/:id' },
  deleteContact: { method: destroy, path: '/platform/contacts/:id' },

  getDatatable: { method: get, path: '/meta-data/datatables/:id' },

  getEmail: { method: get, path: '/platform/emails/:id' },

  getAddresses: { method: get, path: '/platform/addresses' },

  getEvents: { method: get, path: '/platform/events' },
  getPerformers: { method: get, path: '/platform/performers' },
  getEmails: { method: get, path: '/platform/emails' },

  getOrganizations: { method: get, path: '/platform/organizations' },
  createOrganization: { method: post, path: '/platform/organizations' },
  updateOrganization: { method: patch, path: '/platform/organizations/:id' },

  updateEntityAux: { method: patch, path: '/platform/entityaux/:id' },
  createEntityAux: { method: post, path: '/platform/entityaux' },
  getEntityAux: { method: get, path: '/platform/entityaux' },

  getSitemapStates: { method: get, path: '/platform/sitemap' },
  getSitemapState: { method: get, path: '/platform/sitemap/:state' },
  getSitemapStateCounties: {
    method: get,
    path: '/platform/sitemap/:state/counties',
  },
  getSitemapCommunitiesByState: {
    method: get,
    path: '/platform/sitemap/:state/communities',
  },

  getResourceCenterMainInfo: {
    method: get,
    path: '/home-page',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getHomePageInfo: {
    method: get,
    path: '/home',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getContactUsInfo: {
    method: get,
    path: '/contact-us',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getAuthor: { method: get, path: '/authors', jsonApi: false, baseUrl: cmsUrl },
  getReviewer: {
    method: get,
    path: '/reviewers',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getArticle: {
    method: get,
    path: '/articles',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getArticlesCount: {
    method: get,
    path: '/articles/count',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getTopic: { method: get, path: '/topics', jsonApi: false, baseUrl: cmsUrl },
  getTopicHeader: {
    method: get,
    path: '/topics/header',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getAudienceNudges: {
    method: get,
    path: '/audience-nudges',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getAudienceModalContent: {
    method: get,
    path: '/audience-modal-contents/:id',
    jsonApi: false,
    baseUrl: cmsUrl,
  },

  getHubPage: {
    method: get,
    path: '/senior-living-types',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getMarketingPage: {
    method: get,
    path: '/marketing-pages',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getGeoGuidesNew: {
    method: get,
    path: '/geo-guides-news',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getStatePage: {
    method: get,
    path: '/state-pages',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getProfilePage: {
    method: get,
    path: '/profile-page',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getCmsSearchPage: {
    method: get,
    path: '/search-page',
    jsonApi: false,
    baseUrl: cmsUrl,
  },

  createReview: { method: post, path: '/platform/reviews' },
  updateReview: { method: patch, path: '/platform/reviews/:id' },

  getAllReviews: { method: get, path: '/platform/reviews' },
  getReviews: { method: get, path: '/platform/reviews/:entityType/:id' },
  getSearchReviews: {
    method: get,
    path: '/platform/reviews/search/:state/:city',
  },
  getEntityReviews: { method: get, path: '/platform/reviews/:entityType/:id' },

  clientMerge: { method: post, path: '/marketplace/clients-merge' },
  getTypeformResponseDetails: {
    method: get,
    path: '/external/typeform/response',
  },

  createNPS: { method: post, path: '/platform/nps' },

  sendAgentReferral: { method: post, path: '/marketplace/agent-referrals' },
  sendOptionsEmail: { method: post, path: '/marketplace/agent-options' },

  generateAgentTemplate: {
    method: post,
    path: '/marketplace/agent-template/:id',
  },

  getGreenhouseJobs: {
    method: get,
    path: '/:board/jobs',
    baseUrl: greenhouseUrl,
  },
  getResourceCenterCommonInfo: {
    method: get,
    path: '/resource-center-common',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getProviderPage: {
    method: get,
    path: '/provider-pages',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getCommunityPartner: {
    method: get,
    path: '/marketplace/partner-collection/community/:id',
  },
  getProviders: {
    method: get,
    path: '/marketplace/partner-collection/community?page-size=100',
  },
  getNearbyPlaces: {
    method: get,
    path: '/marketplace/nearby/:id',
  },
  applyFormValidation: {
    method: post,
    path: '/platform/communications/validation',
    jsonApi: false,
  },
  getExitIntentSchema: {
    method: get,
    path: '/exit-intent-popup',
    jsonApi: false,
    baseUrl: cmsUrl,
  },
  getSearchInsights: {
    method: get,
    path: '/marketplace/searchinsights',
  },
};
