export const eventNames = {
  LinkClicked: 'Link Clicked',
  ButtonClicked: 'Button Clicked',
  SectionViewed: 'Section Viewed',
  TextEntered: 'Text Entered',
  FormSubmitted: 'Form Submitted',
  FieldFocused: 'Field Focused',
  PageViewed: 'Page Viewed',
  OptionSelected: 'Option Selected',
  OptionUnselected: 'Option Unselected',
  Hovered: 'Component Hovered',
  LinkHovered: 'Link Hovered',
  MapMoved: 'Map moved',
  InfoHovered: 'Info Hovered',
  ImageClicked: 'Image Clicked',
  VideoClicked: 'Video Clicked',
  ModalExited: 'Modal Exited',
  FieldChecked: 'Field Checked',
  FieldUnchecked: 'Field Unchecked',
  ToastShown: 'Toast Shown',
  ModalShown: 'Modal Shown',
  WizardStarted: 'Wizard Started',
  WizardStepCompleted: 'Wizard Step Completed',
  WizardStepSkipped: 'Wizard Step Skipped',
  WizardStepPrevious: 'Wizard Step Previous',
  WizardSubmitted: 'Wizard Submitted',
  TypeformWizardStarted: 'TFWizard Started',
  TypeformWizardSubmitted: 'TFWizard Submitted',
  CommunityListViewed: 'Community List Viewed',
  Swipe: 'Swipe',
  ChatBotInitiated: 'ChatBot Initiated',
  ChatBotOpened: 'ChatBot Opened',
  ChatBotClosed: 'ChatBot Closed',
  TooltipToggled: 'Tooltip Toggled',
  ChatbotFunctionInvoked: 'Chatbot Function Invoked'
};

export const pageNames = {
  CommunityProfile: 'Community Profile',
  Search: 'Search',
  Home: 'Home',
  Topic: 'Topic',
  Article: 'Article',
  Authors: 'Authors',
  Reviewers: 'Reviewers',
  AuthorProfile: 'Author Profile',
  ReviewerProfile: 'Reviewer Profile',
  ResourceCenterHome: 'Resource Center Home',
  ResourceCenterSearch: 'Resource Center Search',
  Hub: 'Hub',
  State: 'State',
  Care: 'Care',
  PartnerCommunities: 'Partner Communities',
  PartnerAgents: 'Partner Agents',
  Legal: 'Legal',
  Agents: 'Agents',
  AgentsRegion: 'Agents Region',
  AgentsRegionCity: 'Agents Region City',
  AgentProfile: 'Agent Profile',
  Career: 'Career',
  Marketing: 'Marketing',
  ReviewWizard: 'Review Wizard',
  Homebase: 'Homebase',
  Compare: 'Compare'
};
