/** @jsxImportSource react */
import { getDisclosureEnabledStatesInD2CCommunities } from '@common/helpers/communities';
import clsxm from '@common/lib/clsxm';
import Carousel from '@react/shared/components/Carousel';
import Spinner from '@react/shared/components/Spinner';
import { useToast } from '@react/shared/components/Toast';
import { delay } from '@react/shared/helpers';
import { Button } from '@react/system';
import Checkmark from '@sly/icons/react/Checkmark';
import { useState } from 'react';

import CommunityCardWithGetPricing from '../CommunityCardWithGetPricing';

export default function RecommendedCommunitiesModal({
  getPricingRequestedListBySlugs,
  getPricingRequestedBySlug,
  createProfileContactActionBySlug,
  createProfileContactActionBySlugs,
  handleContinue,
  recommendedCommunities,
  recommendedReasons,
  eventProps = {},
  isChatMode = false,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pushSuccess, pushError } = useToast();

  const recommendedCommunitiesSlugs = recommendedCommunities.map(
    (community) => community.id
  );

  const pricingRequestedSlugsList = getPricingRequestedListBySlugs(
    recommendedCommunitiesSlugs
  );

  const isPricingRequestedForAny = recommendedCommunitiesSlugs?.length
    ? !!pricingRequestedSlugsList?.length
    : false;

  const handlePricingRequestForAll = async () => {
    setIsSubmitting(true);
    try {
      await createProfileContactActionBySlugs(recommendedCommunitiesSlugs);
      await delay(4000);
      pushSuccess('Pricing has been requested', 1000);
      setIsSubmitting(false);
      // only handles disclousre for the single state (first community)
      // have to handle disclousre for multiple states in future if they are close to each other
      await handleContinue({
        disclosureState: getDisclosureEnabledStatesInD2CCommunities(
          recommendedCommunities
        )?.[0],
      });
    } catch (e) {
      await delay(1000);
      pushError('Opps. Something went wrong, please try again!', 1000);
      setIsSubmitting(false);
    }
  };

  const primaryButtonText = 'Get pricing from all';

  const handleButtonClick = async () => {
    if (!isPricingRequestedForAny) {
      await handlePricingRequestForAll();
    } else {
      const pricingRequestedCommunities = recommendedCommunities.filter(
        (community) => pricingRequestedSlugsList.includes(community.id)
      );

      await handleContinue({
        disclosureState: getDisclosureEnabledStatesInD2CCommunities(
          pricingRequestedCommunities
        )?.[0],
      });
    }
  };

  return (
    <div className='relative'>
      <div
        className={clsxm(
          'space-y-4 bg-white py-6',
          isChatMode ? 'px-2' : 'px-6'
        )}
      >
        <h3 className='font-t-m'>
          Request a call from these nearby community options for pricing
        </h3>

        {!!recommendedReasons?.length && (
          <div className='space-y-2 rounded bg-harvest-lighter-90 p-4'>
            <h4 className='font-medium'>
              Why we think these might be a good fit for you:
            </h4>
            {recommendedReasons?.map((reason) => (
              <div key={reason} className='flex items-center gap-4'>
                <Checkmark className='text-green-base' />
                <div>{reason}</div>
              </div>
            ))}
          </div>
        )}

        <Carousel
          itemsQty={
            recommendedCommunities.length < 3
              ? 3
              : recommendedCommunities.length
          }
        >
          {recommendedCommunities.map((community) => {
            return (
              <>
                <CommunityCardWithGetPricing
                  key={community?.id}
                  community={community}
                  eventProps={eventProps}
                  getPricingRequestedBySlug={getPricingRequestedBySlug}
                  createProfileContactActionBySlug={
                    createProfileContactActionBySlug
                  }
                  isGetPricingDisabled={isSubmitting}
                />
              </>
            );
          })}
        </Carousel>
      </div>
      <div
        className={clsxm(
          'sticky bottom-0 flex flex-col gap-4 border-t border-t-slate-lighter-90 bg-white p-6'
        )}
      >
        <Button
          disabled={isSubmitting}
          variant='primary'
          onClick={handleButtonClick}
          eventProps={{
            ...eventProps,
            location: 'Gwizzy OTM Modal',
            text: isPricingRequestedForAny ? 'Continue' : primaryButtonText,
            cta: !isPricingRequestedForAny ? primaryButtonText : null,
          }}
        >
          {isSubmitting && <Spinner />}
          {isPricingRequestedForAny
            ? 'Continue'
            : isSubmitting
            ? 'Requesting pricing'
            : primaryButtonText}
        </Button>
        {!isPricingRequestedForAny && (
          <Button
            variant='none'
            className='font-medium'
            disabled={isSubmitting}
            onClick={async () => await handleContinue()}
            eventProps={{
              ...eventProps,
              text: 'Skip',
              location: 'Gwizzy OTM Modal',
            }}
          >
            Skip
          </Button>
        )}
      </div>
    </div>
  );
}
