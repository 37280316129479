/** @jsxImportSource react */

import { useQuery } from '@react/services/api';
import AgentCard from '@react/shared/components/AgentCard';
import Spinner from '@react/shared/components/Spinner';
import { delay } from '@react/shared/helpers/delay';
import { Button } from '@react/system';
import { Tag } from '@react/ui/Tag';
import { WIZARD_STEP_COMPLETED } from '@sly/core/constants/actionTypes';
import { Agent } from '@sly/core/types';
import { useState } from 'react';
import { isBrowser } from 'sly/config';

export default function AgentOptIn({
  handleContinue,
  agent,
}: {
  handleContinue: () => Promise<void>;
  agent: Agent;
}) {
  const [submittingType, setSubmittingType] = useState('');
  const createAction = useQuery('createUuidAction');

  const handleUserAction = async (localExpertOptIn: boolean) => {
    setSubmittingType(localExpertOptIn ? 'YES' : 'NO');
    try {
      await createAction({
        type: 'UUIDAction',
        attributes: {
          actionType: WIZARD_STEP_COMPLETED,
          actionPage: isBrowser ? location.pathname : '',
          actionInfo: {
            data: {
              localExpertOptIn: localExpertOptIn,
            },
            stepName: 'wizardPostConversionInfo',
            wizardName: 'gwizzy',
            agentSlug: localExpertOptIn ? agent?.id : null,
          },
        },
      });
      await delay(2000);
    } catch (error) {
      console.error(error);
    }
    await handleContinue();
    setSubmittingType('');
  };

  return (
    <div className='space-y-6 p-6'>
      <div className='space-y-4'>
        <Tag className='uppercase' variant='highlight'>
          Recommended
        </Tag>
        <h3 className='font-t-m'>
          Are you interested in a free consultation from a local senior living
          expert?
        </h3>
        <p>
          Get guidance from their extensive knowledge of community options in
          your area, including services, amenities, costs, and availability—all
          at no cost to you.
        </p>
      </div>
      {agent && <AgentCard className='mt-6' agent={agent} />}
      <div className='flex flex-col gap-2'>
        <Button
          disabled={!!submittingType}
          onClick={() => handleUserAction(true)}
          className='whitespace-pre-wrap'
          eventProps={{
            text: 'Yes, connect me with an advisor for free',
          }}
        >
          {submittingType == 'YES' && <Spinner />}
          Yes, connect me with an advisor for free
        </Button>
        <Button
          variant='custom'
          disabled={!!submittingType}
          // TODO: handle skip case -------
          onClick={() => handleUserAction(false)}
        >
          {submittingType == 'NO' && <Spinner />}
          No thanks, I don’t need help
        </Button>
      </div>
    </div>
  );
}
