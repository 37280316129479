type timeZoneShortCodeType = {
  [key: string]: string;
};

export const timeZoneShortCode: timeZoneShortCodeType = {
  'America/Los_Angeles': 'PST',
  'America/Indiana/Tell_City': 'CST',
  'America/Indiana/Marengo': 'EST ',
  'America/North_Dakota/Beulah': 'CST',
  'Pacific/Honolulu': 'HST',
  'America/Halifax': 'AST',
  'America/New_York': 'EST',
  'America/Anchorage': 'AKST',
  'America/Indiana/Vevay': 'EST',
  'America/Boise': 'MST',
  'America/Chicago': 'CST',
  UTC: 'UTC',
  'America/Indiana/Vincennes': 'EST',
  'America/Denver': 'MST',
  'America/Indiana/Petersburg': 'EST',
  'America/Indiana/Indianapolis': 'EST',
  'America/Detroit': 'EST',
  'America/Indiana/Knox': 'CST',
  'America/Indiana/Winamac': 'EST',
  'Asia/Calcutta': 'IST',
  'America/Phoenix': 'MST'
};
