import { getDefaultCommunityImageUrl } from '@common/helpers/community';
import { retrieveLocalStorage } from '@common/helpers/localStorage';
import { capitalize } from '@common/helpers/utils';
import {
  BUDGET_MAP as budgetMap,
  CONTACT_INFO_FIELDS,
  CONVERSION_FIELDS,
  DisclosureFieldNames,
  HOME_SALE_QUESTION_TYPE,
  MONTHLY_BUDGET_MAP as budgetRangeMap,
} from '@react/gwizzy/constants';
import get from 'lodash/get';

export const getIsLowIncomeFromConversionData = ({
  maxMonthlyBudget,
  payment,
}) =>
  maxMonthlyBudget === budgetRangeMap.under2K &&
  payment?.includes(budgetMap.noAccess);

export const getIsLowIncome = ({ maxMonthlyBudget, payment }) => {
  const paymentArray = Array.isArray(payment)
    ? payment.map((p) => p?.toLowerCase())
    : payment?.toLowerCase()?.split(',');

  if (
    maxMonthlyBudget === budgetRangeMap.under2K &&
    paymentArray?.includes('none')
  ) {
    return true;
  }

  return false;
};

const getHasMobilePhone = (client) => {
  const additionalMetaData = get(client, 'clientInfo.additionalMetadata');

  return additionalMetaData?.includes('MobilePhone');
};

export const getClientMeta = ({ agent, client }) => {
  const maxMonthlyBudget = get(
    client,
    'uuidAux.uuidInfo.financialInfo.maxMonthlyBudget'
  );
  const payment = get(client, 'uuidAux.uuidInfo.financialInfo.payment');
  const lookingFor = get(client, 'uuidAux.uuidInfo.housingInfo.lookingFor');
  const residentName = get(client, 'uuidAux.uuidInfo.residentInfo.fullName');
  const hasAgent = !!agent;
  const isLowIncome = getIsLowIncome({ maxMonthlyBudget, payment });
  const hasMobilePhone = getHasMobilePhone(client);
  const phoneNumber = get(client, 'clientInfo.phoneNumber');
  const searchReasons = get(
    client,
    'uuidAux.uuidInfo.searchInfo.searchReasons'
  );

  return {
    hasAgent: false,
    isLowIncome,
    skipSmsOptIn: !(hasAgent && !isLowIncome),
    skipMobile: !(agent && !isLowIncome && !hasMobilePhone),
    showAgentModal: hasAgent && !isLowIncome,
    phoneNumber,
    skipResidentName: !!residentName || lookingFor === 'Self',
    skipSearchReasons: !!searchReasons,
  };
};

export const getClientMetaFromConversionData = ({
  agent,
  conversionData,
  isMobile,
}) => {
  if (!conversionData) {
    return {};
  }

  const hasAgent = !!agent;
  const { budgetRange, budget, lookingFor, searchReasons } =
    conversionData || {};

  const isLowIncome = getIsLowIncomeFromConversionData({
    maxMonthlyBudget: budgetRange,
    payment: budget,
  });
  const hasMobilePhone = isMobile;

  const phoneNumber = get(conversionData, 'phone');

  return {
    hasAgent,
    isLowIncome,
    skipSmsOptIn: !(hasAgent && !isLowIncome),
    skipMobile: !(agent && !isLowIncome && !hasMobilePhone),
    showAgentModal: hasAgent && !isLowIncome,
    phoneNumber,
    skipResidentName: lookingFor === 'Myself' || conversionData?.residentName,
    lookingFor,
    skipLookingFor: !!lookingFor,
    skipSearchReasons: !!searchReasons,
  };
};

export const getIsPostConversionDone = () => {
  const postConversionLs = retrieveLocalStorage('postConversion');
  const postConversion = postConversionLs ? JSON.parse(postConversionLs) : {};

  return postConversion?.status === 'done';
};

export const getIsConversionDone = () => {
  const conversionLs = retrieveLocalStorage('conversionWizard');
  const conversion = conversionLs ? JSON.parse(conversionLs) : {};

  return conversion?.status === 'done';
};

export const getShouldShowPostConversion = ({ client }) => {
  if (!client) {
    return false;
  }

  const maxMonthlyBudget = get(
    client,
    'uuidAux.uuidInfo.financialInfo.maxMonthlyBudget'
  );
  const payment = get(client, 'uuidAux.uuidInfo.financialInfo.payment');
  const isLowIncome = getIsLowIncome({
    maxMonthlyBudget,
    payment,
  });

  if (getIsPostConversionDone() || isLowIncome) {
    return false;
  }

  const { uuidAux } = client;

  const smsOptIn = get(uuidAux, 'info.communicationInfo.smsOptIn');
  const timePreference = get(uuidAux, 'info.tourInfo.timePreference');
  const additionalServices = get(uuidAux, 'info.searchInfo.additionalServices');

  return !(smsOptIn && timePreference && additionalServices);
};

export const getAdlOption = (isSelf) =>
  CONVERSION_FIELDS.adl.options.reduce((acc, curr) => {
    if (isSelf) {
      if (curr.value !== 'memory-care') {
        acc.push(curr);
      }
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

export const validateFields = (fields) => (values) => {
  if (!fields) {
    return {};
  }
  const errors = {};

  fields.forEach((field) => {
    const { name, validate, hidden } = field;
    if (hidden && hidden({ values })) {
      return errors;
    }
    if (validate) {
      const invalid = validate(get(values, name));
      if (invalid) {
        errors[name] = invalid;
      }
    }
  });
  return errors;
};

export const isSelf = (values) => values?.lookingFor === 'Myself';

export const getNameOrYou = (values, variant) => {
  const { residentName } = values || {};

  const firstName = residentName
    ? capitalize(residentName.trim().split(' ')[0].toLowerCase())
    : '';

  switch (variant) {
    case 'your': {
      return firstName ? firstName + "'s" : 'your';
    }
    case 'you': {
      return firstName || 'you';
    }
    case 'areYou': {
      return firstName ? 'is ' + firstName : 'are you';
    }
    case 'haveYou': {
      return firstName ? 'Has ' + firstName : 'Have you';
    }
    default: {
      return firstName || 'you';
    }
  }
};

export function getContactSkip(contactInfo) {
  const skip = { email: false, phone: false };
  switch (contactInfo) {
    case CONTACT_INFO_FIELDS.emailOnly: {
      skip.phone = true;
      break;
    }
    case CONTACT_INFO_FIELDS.phoneOnly: {
      skip.email = true;
      break;
    }
    default: {
      break;
    }
  }
  return skip;
}

export const getIsTourPostConversionDone = () => {
  const conversionLs = retrieveLocalStorage('tourPostConversion');
  const conversion = conversionLs ? JSON.parse(conversionLs) : {};
  return conversion?.status === 'done';
};

export const getDisclosureFieldsByState = (state) => {
  return DisclosureFieldNames[state] || {};
};

export const getHomeSaleQuestionType = (homeSaleQuestionType) =>
  homeSaleQuestionType && HOME_SALE_QUESTION_TYPE[homeSaleQuestionType];

export const getCommunityToggleOptions = (communities) => {
  return (
    communities?.map(({ name, id, imagePath, distance, propInfo }) => ({
      label: name,
      value: id,
      imagePath,
      defaultImage: getDefaultCommunityImageUrl(id, propInfo?.communitySize),
      distance: distance,
    })) || []
  );
};

export const shouldAskResNameInPreConv = (values, isResidentNameRequired) => {
  if (!isResidentNameRequired || isSelf(values)) {
    return false;
  }
  return true;
};
