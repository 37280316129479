/** @jsxImportSource react */
import usePosition from '@react/shared/hooks/usePosition';
import Chevron from '@sly/icons/react/Chevron';
import cx from 'classnames';
import React from 'react';

function Carousel({
  itemsQty,
  className,
  innerClassName = '',
  children,
  hideChevron = false,
  scrollbar = false,
}) {
  const ref = React.useRef();

  const { hasItemsOnLeft, hasItemsOnRight, scrollRight, scrollLeft } =
    usePosition(ref);

  const showChevron = itemsQty > 2 && !hideChevron;

  return (
    <div
      className={cx('relative h-[inherit]', className)}
      role='region'
      aria-label='carousel'
    >
      <div
        className={cx(
          'carousel -mx-m mt-s',
          'grid h-full snap-x snap-mandatory ',
          'list-none auto-cols-[18rem] grid-flow-col items-start gap-m ',
          'px-m md:-mx-s md:-mt-s',
          'md:auto-cols-[20.5rem] md:gap-x-l md:p-s ',
          innerClassName,
          scrollbar ? 'overflow-x-auto' : 'no-scrollbar overflow-x-scroll'
        )}
        ref={ref}
        data-testid='carousel'
      >
        {children}
      </div>
      {showChevron && (
        <>
          <div
            className='z-1 absolute top-[45%] left-0 hidden translate-x-[-50%] translate-y-[-50%] cursor-pointer rounded-full border-0 bg-white p-xs drop-shadow-md transition-all md:block'
            onClick={scrollLeft}
            aria-label='Previous slide'
            data-testid='carousel-prev'
          >
            <Chevron
              size='l'
              className={cx(
                '-rotate-90',
                hasItemsOnLeft
                  ? 'text-slate-lighter-base'
                  : 'text-slate-lighter-90'
              )}
            />
          </div>
          <div
            className='z-1 absolute top-[45%] right-0 hidden translate-x-[50%] translate-y-[-50%] cursor-pointer rounded-full border-0 bg-white  p-xs drop-shadow-md transition-all md:block'
            onClick={scrollRight}
            aria-label='Next slide'
            data-testid='carousel-next'
          >
            <Chevron
              size='l'
              className={cx(
                'rotate-90',
                hasItemsOnRight
                  ? 'text-slate-lighter-base'
                  : 'text-slate-lighter-90'
              )}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Carousel;
