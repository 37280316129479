/** @jsxImportSource react */
import { standardizeSearchParam } from '@react/search/helpers';
import { Link } from '@react/system';

import { AdlLevel } from '../../types/index';

export const MismatchWarning = ({
  city,
  state,
  care,
  adl,
}: {
  city?: string;
  state?: string;
  care: string[];
  adl?: AdlLevel;
}) => {
  if (!adl || !care?.length || !city || !state) return <></>;

  if (
    care?.length <= 1 &&
    (care?.includes('Skilled Nursing Facility') ||
      care?.includes('Continuing Care Retirement Community(CCRC)'))
  )
    return <></>;

  const citySlug = standardizeSearchParam('city', city);
  const stateFullName = standardizeSearchParam('state', state);

  switch (adl) {
    case 'no-care':
      return (
        <>
          {!care.includes('Independent Living') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community may not be the best fit for your needs right now, as they only offer ${
                care?.length >= 2
                  ? `${care?.[0]?.toLowerCase()} and ${care?.[1]?.toLowerCase()}`
                  : care?.[0]?.toLowerCase()
              }. Based on your responses, it seems as though you don't require additional care support right now. If you'd like to see a list of independent living communities in your area, `}
              <Link to={`/independent-living/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    case 'light-care':
      return (
        <>
          {!care?.includes('Independent Living') &&
            !care?.includes('Assisted Living') && (
              <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
                {`This community might not be the best fit for your needs as they don't currently offer assistance to individuals who require light care. If you'd like to see a list of independent living communities in your area, `}
                <Link to={`/independent-living/${stateFullName}/${citySlug}`}>
                  click here.
                </Link>

                {` Alternatively, If you'd like to see a list of assisted living communities in your area, `}
                <Link to={`/assisted-living/${stateFullName}/${citySlug}`}>
                  click here.
                </Link>
              </div>
            )}
        </>
      );
    case 'heavy-care':
      return (
        <>
          {!care?.includes('Assisted Living') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community might not be the best fit for your needs as they don't currently offer assistance to individuals who require assistance with acitivies of daily living. If you'd like to see a list of assisted living communities in your area, `}
              <Link to={`/assisted-living/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    case 'memory-care':
      return (
        <>
          {!care?.includes('Memory Care') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community might not be the best fit for your needs as they don't currently offer assistance to individuals who require assistance with memory care. If you'd like to see a list of memory care communities in your area, `}
              <Link to={`/memory-care/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default MismatchWarning;
