/** @jsxImportSource react */
/* eslint-disable @next/next/no-img-element */

import { cn } from '@common/lib/clsxm';
import CardBadge from '@react/card/components/CardBadge';
import { gallery, info, tile } from '@react/card/components/CardClasses';
import CardGallery from '@react/card/components/CardGallery';
import useCommunityCard from '@react/card/hooks/useCommunityCard';
import { eventNames, useEvents } from '@react/services/events';
import Spinner from '@react/shared/components/Spinner';
import { useToast } from '@react/shared/components/Toast';
import { delay } from '@react/shared/helpers';
import { Button } from '@react/system';
import { Tag } from '@react/ui/Tag';
import { useState } from 'react';

export default function CommunityCardWithGetPricing({
  community,
  eventProps = {},
  getPricingRequestedBySlug,
  createProfileContactActionBySlug,
  isGetPricingDisabled,
}) {
  const { events } = useEvents();
  const {
    name,
    addressString,
    formattedMoney,
    reviewsValue,
    numReviews,
    tagsArray,
    typeCare,
  } = useCommunityCard({
    entity: community,
  });

  const handleCommunityCardClick = () => {
    events.track(eventNames.ButtonClicked, {
      entry: eventProps?.entry,
      text: 'Trying to open a community',
      location: 'Gwizzy OTM Modal',
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pushSuccess, pushError } = useToast();

  const { id } = community;

  const isPricingRequested = getPricingRequestedBySlug(id) === community.id;

  const handlePricingRequest = async () => {
    setIsSubmitting(true);
    try {
      await createProfileContactActionBySlug(id);
      await delay(1000);
      pushSuccess(`Pricing for ${name} has been requested`, 2000);
      setIsSubmitting(false);
    } catch (e) {
      await delay(1000);
      pushError('Opps. Something went wrong, please try again!', 1000);
      setIsSubmitting(false);
    }
  };

  const intent = 'vertical';

  return (
    <div className={tile({ intent })} onClick={handleCommunityCardClick}>
      <div className={gallery({ intent })}>
        {tagsArray?.length > 0 && (
          <div className='absolute top-2 left-2 z-10 flex flex-row flex-wrap gap-1'>
            {tagsArray.map((tag) => (
              <CardBadge key={tag?.text} tag={tag} intent={intent} />
            ))}
          </div>
        )}
        <CardGallery
          entity={community}
          intent={intent}
          preloadFirstImage={true}
          newTab={true}
        />
      </div>
      <div className={cn(info({ intent }), 'space-y-4')}>
        <div>
          <h4 className='font-t-s'>{name}</h4>
          <p className='font-b-s line-clamp-1'>{addressString}</p>
          {typeCare && typeCare?.length && (
            <p className='font-b-s line-clamp-1'>{typeCare.join(' \u00B7 ')}</p>
          )}
        </div>
        <div className='flex items-center justify-between'>
          {formattedMoney && (
            <h5 className='font-medium'>From {formattedMoney}/mo</h5>
          )}

          {!!(numReviews && reviewsValue) && (
            <div className='flex items-center gap-1'>
              <Tag className='border-transparent bg-red-base px-1 text-white'>
                {reviewsValue}
              </Tag>
              <span>
                ({numReviews} review{numReviews > 1 && 's'})
              </span>
            </div>
          )}
        </div>
        <Button
          disabled={isSubmitting || isPricingRequested || isGetPricingDisabled}
          variant='primary'
          onClick={(e) => {
            e.stopPropagation();
            handlePricingRequest();
          }}
          eventProps={{
            ...eventProps,
            location: 'Gwizzy OTM Modal',
            text: 'Get pricing',
            cta: 'Get pricing',
          }}
        >
          {isSubmitting && <Spinner />}
          {isSubmitting
            ? 'Requesting pricing'
            : isPricingRequested
            ? 'Pricing requested'
            : 'Get Pricing'}
        </Button>
      </div>
    </div>
  );
}
