export const ROOM_PREFERENCES_OPTIONS = [
  {
    label: 'Private Suite',
    value: 'Private Suite'
  },
  {
    label: 'Studio',
    value: 'Studio'
  },
  {
    label: '1 Bedroom',
    value: '1 Bedroom'
  },
  {
    label: '2 Bedroom',
    value: '2 Bedroom'
  },
  {
    label: 'Other',
    value: 'Other'
  },
  {
    label: 'Shared Suite',
    value: 'Shared Suite'
  }
];

export const FAMILY_INTERESTS_OPTIONS = [
  { label: 'Talking to family and friends', value: 'friends-family' },
  { label: 'Spiritual/faith based activity', value: 'faith-activity' },
  { label: 'Walk or physical activity', value: 'physical-activity' },
  { label: 'Being outdoors', value: 'outdoor-activity' },
  { label: 'A good meal', value: 'meal' },
  { label: 'TV, movies or music', value: 'tv' }
];

export const MENTAL_HEALTH_OPTIONS = [
  { label: 'Memory issues', value: 'memory' },
  { label: 'Hallucinations', value: 'hallucinations' },
  { label: 'Wandering', value: 'wandering' },
  { label: 'Aggressiveness towards others', value: 'aggression' },
  { label: 'Withdrawal from activities', value: 'withdrawal' },
  { label: 'Inappropriate behaviors', value: 'inappropriateness' },
  { label: 'None', value: 'none' }
];

const FAMILY_AGENT_FILTER_OPTIONS = [
  'resident',
  'stage',
  'phone',
  'email',
  'updated',
  'created'
];

export const FAMILY_ADMIN_FILTER_OPTIONS = [
  ...FAMILY_AGENT_FILTER_OPTIONS,
  'status',
  'is-contract',
  'city',
  'state',
  'agent',
  'invoice-number',
  'invoice-paid'
];
