/** @jsxImportSource react */
import { calcYearsOfExperience } from '@common/helpers/date';
import { formatRating } from '@common/helpers/rating';
import { getAgentUrl } from '@common/helpers/url';
import clsxm from '@common/lib/clsxm';
import { Link } from '@react/system';
import { getAgentStats } from '@sly/core/helpers/agent';
import { Agent } from '@sly/core/types';
import {
  Award,
  Business,
  FamilyStroke,
  Location,
  Star,
} from '@sly/icons/react';

import IconWithText from '../CheckmarkWithText';

export default function AgentStats({
  agent,
  eventProps,
  className,
}: {
  agent: Agent;
  eventProps?: Record<string, any>;
  className?: string;
}) {
  const {
    ratingValue,
    numRatings,
    recentFamiliesHelped,
    operatingIn,
    inBusinessSince,
    awards,
  } = getAgentStats(agent);

  return (
    <ul className={clsxm('grid gap-4 md:grid-cols-2', className)}>
      {!!operatingIn && (
        <IconWithText iconSize='s' Icon={Location}>
          Serves in {operatingIn}
        </IconWithText>
      )}
      {ratingValue > 0 && (
        <IconWithText
          iconClassName='text-yellow-base'
          iconSize='s'
          Icon={Star}
          isIconActive
        >
          {formatRating(ratingValue)}{' '}
          {numRatings > 0 && (
            <Link
              eventProps={eventProps}
              to={`${getAgentUrl(agent)}#reviews`}
              className='!text-slate-base'
            >
              (
              <span className='underline'>
                {numRatings} {numRatings === 1 ? ' review' : ' reviews'}
              </span>
              )
            </Link>
          )}
        </IconWithText>
      )}
      {!!recentFamiliesHelped && (
        <IconWithText iconSize='s' Icon={FamilyStroke}>
          Families helped: {recentFamiliesHelped}
        </IconWithText>
      )}
      {!!inBusinessSince && (
        <IconWithText iconSize='s' Icon={Business}>
          {calcYearsOfExperience(inBusinessSince)} years of experience
        </IconWithText>
      )}
      {awards && (
        <IconWithText iconSize='s' Icon={Award} isIconActive>
          Top advisor: {awards}
        </IconWithText>
      )}
    </ul>
  );
}
