import Recs from '@sly/frontend/react/recs';
import ToastProvider from '@sly/frontend/react/shared/components/Toast/ToastProvider';
import { IconContext } from '@sly/icons/Icon';
import Script from 'next/script';
import React, { memo, useMemo } from 'react';
import { isBrowser } from 'sly/config';
import { ApiProvider, createApiClient } from 'sly/services/api';
import apiEndpoints from 'sly/services/api/endpoints';
import { AuthProvider } from 'sly/services/auth';
import { BreakpointProvider } from 'sly/services/breakpoint';
import ChatBoxProvider from 'sly/services/chatbox/ChatBoxContext';
import { UserCookiesContainer } from 'sly/services/cookies';
import SegmentContextProvider from 'sly/services/events/segmentContext';
import ErrorLogBoundary from 'sly/services/logger/components/ErrorLogBoundary';
import { PopupsProvider } from 'sly/services/popupsContext';
import RetentionPopupProvider from 'sly/services/retentionPopup/RetentionPopupProvider';

// import '../scripts/wdyr';
import '@sly/config/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

if (typeof window !== 'undefined' && 'ResizeObserver' in window === false) {
  console.log('polyfilling ResizeObserver');
  import('resize-observer').then(({ install }) => install());
}

if (isBrowser && window.HTMLDialogElement === undefined) {
  import('@react/shared/styles/dialog-polyfill.css');
}

import '@typeform/embed/build/css/widget.css';

function MyApp({ Component, pageProps, iconsContext = {} }) {
  const { initialApiState, enableNewChatBot = false } = pageProps;
  const apiContext = useMemo(
    () => ({
      apiClient: createApiClient(apiEndpoints, {
        initialState: initialApiState,
      }),
      skipApiCalls: false,
    }),
    []
  );

  return (
    <ErrorLogBoundary>
      <ApiProvider value={apiContext}>
        <AuthProvider>
          <UserCookiesContainer>
            <IconContext.Provider value={iconsContext}>
              <SegmentContextProvider>
                <PopupsProvider>
                  <RetentionPopupProvider>
                    <ChatBoxProvider>
                      <BreakpointProvider>
                        <ToastProvider variant='bottom_middle'>
                          <Component {...pageProps} />
                          <Recs />
                        </ToastProvider>
                      </BreakpointProvider>
                    </ChatBoxProvider>
                  </RetentionPopupProvider>
                </PopupsProvider>
              </SegmentContextProvider>
            </IconContext.Provider>
          </UserCookiesContainer>
        </AuthProvider>
      </ApiProvider>
      {!enableNewChatBot && (
        <Script
          id='instabot'
          strategy='lazyOnload'
          defer
          dangerouslySetInnerHTML={{
            __html: `(function () {
              setTimeout(function(){
                var s1 = document.createElement('script');
                s1.src = 'https://widget.instabot.io/jsapi/rokoInstabot.js';
                s1.id = 'instabot';
                s1.type = 'text/javascript';
                s1.text = 'apiKey: "OgRs7tffvTdiKOKqsDSwwLgyJF6wHYVxFAK+qQO4paU="';
                s1.async = true;
                s1.crossOrigin = '';
                document.body.appendChild(s1);
              }, 15000)
            })();`,
          }}
        />
      )}
    </ErrorLogBoundary>
  );
}

export default memo(MyApp);
